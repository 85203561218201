import * as React from "react"
import Layout from "layouts/Layout"

const NotFoundPage = () => (
  <Layout title="404: Not found">
    <div className="container">
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Layout>
)

export default NotFoundPage
