import React from "react"
import Layout from "../layouts/Layout"
import "assets/styles/about.scss";

const About = () => {
  return (
    <Layout title={"About"}>
      <div className="about_wrapper">
        <div className="container">
          <div className="title_wrapper">
            <h3>Rakshai Kumar</h3>
            <p>
              I am a professional graphic designer with years of experience in creating stunning visual designs for a variety of clients. My portfolio showcases a range of design projects.
            </p>
            <p> Please take a look around my portfolio and don't hesitate to contact me for more information or to discuss your project in more detail.</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default About
