import React, { useState } from "react"
import Layout from "../layouts/Layout"
import "assets/styles/contact.scss";

const initialState = {
  name: "",
  email: "",
  message: ""
}

// const initialErrorState = {
//   name: "",
//   email: "",
//   message: ""
// }

const Contact = () => {
  const [formState, setFormState] = useState(initialState);
  // const [formErrorState, setFormErrorState] = useState(initialErrorState);
  const [success, setSuccess] = useState(false);

  const handleChange = (a) => {
    setFormState(prev => ({ ...prev, [a.target.name]: a.target.value }))
  }

  const formSubmit = (e) => {
    e.preventDefault();
    if (formState?.name && formState?.email && formState?.message) {
      setFormState(initialState);
      setSuccess(true)
    }
  }

  return (
    <Layout title={"Contact"}>
      <div className="contact_wrapper">
        <div className="container">
          <div className="title_wrapper">
            <h3>Contact Me</h3>
            <p>
              You can contact me by mail, by phone, at my place, or by leaving your valuable comment.
            </p>
          </div>
          {success && <div className="alert alert-success success_message">Thank you for contacting us.</div>}
          <div className="contact_box">
            <div className="upper_section">
              <div className="email">
                {/* <FontAwesomeIcon icon={famail} /> */}
              </div>
            </div>
            <div className="lower_section">
              <form onSubmit={formSubmit}>
                <label htmlFor="name">
                  Name * <br />
                  <input type="text" value={formState?.name} onChange={handleChange} name="name" id="name" required />
                </label>
                <label htmlFor="email">
                  Email *<br />
                  <input type="email" value={formState?.email} onChange={handleChange} name="email" id="email" required />
                </label>

                <label htmlFor="message">
                  Message *<br />
                  <textarea name="message" value={formState?.message} onChange={handleChange} id="message" cols="30" rows="3" required />
                </label>
                {/* <p className="alert alert-danger">gtfdgfd</p> */}
                <button type="submit" onClick={(e) => formSubmit(e)} className="form_submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contact
