/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
function Seo({ meta, title }) {

  const metaDescription = "Welcome to my graphic design portfolio! I am a professional graphic designer with years of experience in creating stunning visual designs for a variety of clients. My portfolio showcases a range of design projects. Please take a look around my portfolio and don't hesitate to contact me for more information or to discuss your project in more detail."
  const defaultTitle = "Rakshai"
  const titleX = title ? title : defaultTitle

  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title={titleX}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: titleX,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: "@Vishal" || ``,
        },
        {
          name: `twitter:title`,
          content: titleX,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

Seo.defaultProps = {
  meta: [],
  description: ``,
}

Seo.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default Seo
