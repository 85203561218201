import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from 'pages/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import About from 'pages/About';
import Contact from 'pages/Contact';
import NotFoundPage from 'pages/404';

// const

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
