import React from 'react'
import Header from './Header'
import Seo from '../components/Seo';
import "assets/styles/common.scss";

const Layout = ({ title, children }) => {
  return (
    <div>
      <Seo title={title} />
      <Header />
      <main>
        {children}
      </main>
    </div>
  )
}

export default Layout